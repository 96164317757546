<template>
  <div class="container">
      <div class="bg_wra">新闻中心</div>
    <div class="details_con">
      <a-page-header
          style="padding: 0;"
          title="新闻详情"
          sub-title=""
          @back="$router.go(-1)"
      />
      <div class="title">{{info.title}}</div>
      <div class="subtitle">发表时间：{{info.time}} <span style="margin-left: 20px;">作者：{{info.createEmpName}}</span></div>
      <a-divider></a-divider>
      <div class="content" v-html="info.content"></div>
    </div>
  </div>
</template>

<script>
// import {getDetails} from "../api";
import $ from 'jquery'
export default {
  name: "NewsDetails",
  data(){
    return{
      info:''
    }
  },
  methods:{
    /*async getInfo(){
      const res= await getDetails({id:this.$route.params.id})
      console.log(res.data)
      if(res.data.status.code==200){
        this.info=res.data.result
      }
    },*/
    getInfoDetail(){
      var _info = [];
      $.ajax({
        type: "get",
        url: "http://ccsc.9zhinet.com/yzNews/findById?id=" + this.$route.params.id,
        dataType: "json",
        async:false,
        data:{
          pageNumber:1,
          pageSize:30,
          dId:'ed59c7525d534eada0b8734211fb51ca',
          name:''
        },
        success:function(res) {
          _info = res.data;
        }
      })
      return _info;
    },
  },
  created() {
    this.info =  this.getInfoDetail();
    // this.getInfo()
  }
}
</script>

<style lang="less" scoped>
    .bg_wra {
        height: 480px;
        line-height: 480px;
        text-align: center;
        font-size: 40px;
        color: #fff;
        background: url("../assets/image/bg_news.png") no-repeat center;
        background-size: cover;
    }
.container{
    background: #f9f9f9;
  /*padding: 100px 0;*/
    padding: 0 0 100px;
    .content {
      /deep/ img{
        text-align: center;
      }
    }
  .details_con{
    width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: #ffffff;
    .title{
      margin-top: 20px;
      text-align: center;
      font-size: 24px;
    }
    .subtitle{
      margin-top: 10px;
      text-align: center;
      color: #999;
    }
  }
}
@media screen and (max-width: 768px){
  .container{
    padding: 60px 10px;
    .details_con{
      width: 100%;
      .title{
        font-size: 18px;
      }
      .content{
        /deep/ img{
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
</style>
